import { Col, Collapse, Row, Select, Space, Tooltip } from "antd"
import Search from "antd/es/input/Search"
import React, { useEffect, useState } from "react"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import Button from "src/components/MyButton/Button"
import TableCustom from "src/components/Table/CustomTable"
import {
  MainTableData,
  MainTableHeader,
  SubTableData,
  SubTableHeader,
} from "src/components/Table/CustomTable/styled"
import { StyleSearch } from "src/pages/ADMIN/BookUse/styled"
import NewUserModal from "./Modals/NewUserModal"
import PractiseService from "src/services/PractiseService"
import PeopleAdd from "./Modals/UserJoin/PeopleAdd"
import TeamAdd from "./Modals/UserJoin/TeamAdd"
import styled from "styled-components"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import { useParams } from "react-router-dom"
import STORAGE, { getStorage } from "src/lib/storage"
import SvgIcon from "src/components/SvgIcon"

export const Styled = styled.div`
  tr.ant-table-row.ant-table-row-level-1 {
    display: none;
  }
  .icon-ds {
    display: none;
  }
  .Text-title {
    color: rgb(34, 96, 189);
  }
`
const UserJoin = ({ data, organizers, detail }) => {
  const [loading, setLoading] = useState(false)
  const [DataUser, setDataUser] = useState()
  const [pagination, setPagination] = useState({
    page_size: 20,
    page_number: 1,
  })
  const [buttonBTC, setButtonBTC] = useState(false)

  const userInfo = getStorage(STORAGE.USER_INFO)
  const [total, setTotal] = useState()
  const [openModel, setOpenModal] = useState(false)
  const [openModelPeople, setOpenModalPeople] = useState(false)
  const [openModelTeam, setOpenModalTeam] = useState(false)
  const [listRole, setListRole] = useState()
  const [listPractise, setListPractise] = useState()
  const [button, setButtonData] = useState()

  // const [expandedRowKey, setExpandedRowKey] = useState(null) // State for the expanded row key
  const { id } = useParams()
  useEffect(() => {
    if (!!userInfo) {
      userInfo?.role?.practise?.forEach(user => {
        if (user?.practise_id === id) {
          switch (user?.role_group_id) {
            case 7:
              setButtonBTC(true)
              break
            // Add other cases if needed
            default:
              setButtonBTC(false)
              break
          }
        }
      })
    }
  }, [userInfo, id])
  const getData = async () => {
    try {
      setLoading(true)
      const resp = await PractiseService.getUserInPractise({
        ...pagination,
        practise_id: id,
      })
      if (resp.isOk) {
        setDataUser(resp?.data)
        getListRole()
        getListPractise()
        setButtonData(resp?.button)
      }
    } finally {
      setLoading(false)
    }
  }
  const [isRegister, setIsRegister] = useState(false)
  const getListPractise = async event => {
    try {
      setLoading(true)
      const resp = await PractiseService.getRoleInPractise({
        isRegister: !!isRegister ? isRegister : undefined,
      })
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.description,
            type: item?.type,
          }
        })
        setListPractise(ls)
      }
    } finally {
      setLoading(false)
    }
  }

  const getListRole = async () => {
    try {
      setLoading(true)
      const resp = await PractiseService.getPositionInPractise()
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.value,
          }
        })
        setListRole(ls)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [pagination, isRegister])

  // const handleExpand = (expanded, record) => {
  //   setExpandedRowKey(expanded ? record.key : null)
  // }

  const expandedRowRender = record => {
    return (
      <TableCustom
        isPrimary
        columns={columnsTV}
        dataSource={record.children.map(item => ({
          ...item,
          key: item.practise_id,
        }))}
        pagination={false}
      />
    )
  }

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        <div className="text-center">
          {!record?.team_name &&
            idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Họ và tên",
      dataIndex: "User",
      key: "User",
      render: (val, record, idx) => (
        <div className="text-center">{val?.fullname}</div>
      ),
    },
    {
      title: (
        <>
          <MainTableHeader>Điện thoại</MainTableHeader>
          <SubTableHeader>Email</SubTableHeader>
        </>
      ),
      dataIndex: "email",
      key: "email",
      render: (val, record) => (
        <Tooltip title={record?.User?.email}>
          <MainTableData>{record?.User?.phone_number}</MainTableData>
          <SubTableData>{record?.User?.email}</SubTableData>
        </Tooltip>
      ),
    },
    {
      title: "Vai trò trong diễn tập",
      dataIndex: "value",
      key: "value",
      render: (val, record) => (
        <Tooltip title={val}>{record?.allcode?.value}</Tooltip>
      ),
    },
    {
      title: "Quyền",
      dataIndex: "description",
      key: "description",
      render: (val, record) => (
        <Tooltip title={val}>{record?.RoleGroup?.description}</Tooltip>
      ),
    },
    {
      title: "Cơ quan",
      dataIndex: "organization_name",
      key: "organization_name",
      align: "center",
      render: (val, record) => (
        <Tooltip title={record?.User?.Organization?.organization_name}>
          <MainTableData>
            {record?.User?.Organization?.organization_name}
          </MainTableData>
        </Tooltip>
      ),
    },
    {
      title: "Chức vụ",
      dataIndex: "job_title",
      key: "job_title",
      align: "center",
      render: (val, record) => (
        <Tooltip title={val}>{record?.User?.job_title}</Tooltip>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (val, record) => (
        <Tooltip title={val}>
          {record?.status == "0" ? "Chưa tham gia" : "Đã tham gia"}
        </Tooltip>
      ),
    },
  ]
  const columnsTV = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        <div className="text-center">
          {!record?.team_name &&
            idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Họ và tên",
      dataIndex: "User",
      key: "User",
      render: (val, record, idx) => (
        <div className="text-center">{val?.fullname}</div>
      ),
    },
    {
      title: (
        <>
          <MainTableHeader>Điện thoại</MainTableHeader>
          <SubTableHeader>Email</SubTableHeader>
        </>
      ),
      dataIndex: "email",
      key: "email",
      render: (val, record) => (
        <Tooltip title={record?.User?.email}>
          <MainTableData>{record?.User?.phone_number}</MainTableData>
          <SubTableData>{record?.User?.email}</SubTableData>
        </Tooltip>
      ),
    },
    {
      title: "Vai trò trong diễn tập",
      dataIndex: "value",
      key: "value",
      render: (val, record) => (
        <Tooltip title={val}>{record?.allcode?.value}</Tooltip>
      ),
    },
    {
      title: "Quyền",
      dataIndex: "description",
      key: "description",
      render: (val, record) => (
        <Tooltip title={val}>{record?.RoleGroup?.description}</Tooltip>
      ),
    },
    {
      title: "Cơ quan",
      dataIndex: "organization_name",
      key: "organization_name",
      align: "center",
      render: (val, record) => (
        <Tooltip title={record?.User?.Organization?.organization_name}>
          <MainTableData>
            {record?.User?.Organization?.organization_name}
          </MainTableData>
        </Tooltip>
      ),
    },
    {
      title: "Chức vụ",
      dataIndex: "job_title",
      key: "job_title",
      align: "center",
      render: (val, record) => (
        <>
          <Tooltip title={val}>{record?.User?.job_title}</Tooltip>
          {/* <FloatActionWrapper size="small" className="float-action__wrapper">
            <Space>
              <ButtonCircle
                title="Chuyển đội"
                iconName="re-post"
                onClick={() => {
                  setOpenModal({
                    data: record,
                    isdata: true,
                  })
                  setIsRegister(true)
                }}
              />
            </Space>
          </FloatActionWrapper> */}
        </>
      ),
    },
    {
      title: "Cam kết",
      dataIndex: "commit_url",
      key: "commit_url",
      align: "center",
      render: (val, record) => (
        <>
          <Tooltip title={val}>
            <MainTableData>{record?.commit_url}</MainTableData>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (val, record) => (
        <>
          <Tooltip title={val}>
            <MainTableData>
              {record?.status == "0" ? "Chưa tham gia" : "Đã tham gia"}
            </MainTableData>
          </Tooltip>
          <FloatActionWrapper size="small" className="float-action__wrapper">
            <Space>
              <ButtonCircle
                title="Chuyển đội"
                iconName="re-post"
                onClick={() => {
                  setOpenModal({
                    data: record,
                    isdata: true,
                  })
                  setIsRegister(true)
                }}
              />
              <ButtonCircle
                title="Tải Cam kết"
                iconName="dowload-export"
                onClick={() => {
                  handleDownload(record)
                }}
              />
            </Space>
          </FloatActionWrapper>
        </>
      ),
    },
  ]
  const columnsTab = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        <div className="text-center">
          {idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Tên đội",
      dataIndex: "team_name",
      key: "team_name",
      render: (val, record, idx) => (
        <div className="text-center">
          <h5>{record?.team_name}</h5>
        </div>
      ),
    },
  ]

  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex label-title">
          <div className="icon-ds">
            <SvgIcon name="organization" />
          </div>
          <div className="ml-5 text-step">Ban tổ chức</div>
        </div>
      ),
      children: (
        <div>
          <TableCustom
            loading={loading}
            isPrimary
            columns={columns}
            dataSource={DataUser?.BAN_TO_CHUC?.object?.map(item => ({
              ...item,
              key: item.id,
            }))}
            sticky={{ offsetHeader: -14 }}
            scroll={{ x: "800px" }}
            style={{ marginBottom: 4 }}
            expandable={{
              expandedRowRender,
              rowExpandable: record =>
                record.children && record.children.length > 0,
              // expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
              // onExpand: handleExpand,
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="d-flex label-title">
          <div className="icon-ds">
            <SvgIcon name="sword-two" />
          </div>
          <div className="ml-5 text-step">Đội tấn công</div>
        </div>
      ),
      children: (
        <div>
          <TableCustom
            loading={loading}
            isPrimary
            columns={columnsTab}
            dataSource={DataUser?.DOI_TAN_CONG?.object?.map(item => ({
              ...item,
              key: item.id,
            }))}
            sticky={{ offsetHeader: -14 }}
            scroll={{ x: "800px" }}
            style={{ marginBottom: 4 }}
            expandable={{
              expandedRowRender,
              rowExpandable: record =>
                record.children && record.children.length > 0,
              // expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
              // onExpand: handleExpand,
            }}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="d-flex label-title">
          <div className="icon-ds">
            <SvgIcon name="defense" />
          </div>
          <div className="ml-5 text-step">Đội phòng thủ</div>
        </div>
      ),
      children: (
        <div>
          <TableCustom
            loading={loading}
            isPrimary
            columns={columnsTab}
            dataSource={DataUser?.DOI_PHONG_THU?.object?.map(item => ({
              ...item,
              key: item.id,
            }))}
            sticky={{ offsetHeader: -14 }}
            scroll={{ x: "800px" }}
            style={{ marginBottom: 4 }}
            expandable={{
              expandedRowRender,
              rowExpandable: record =>
                record.children && record.children.length > 0,
              // expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
              // onExpand: handleExpand,
            }}
          />
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="d-flex label-title">
          <div className="icon-ds">
            <SvgIcon name="people-team" />
          </div>
          <div className="ml-5 text-step">Thành viên</div>
        </div>
      ),
      children: (
        <div>
          <TableCustom
            loading={loading}
            isPrimary
            columns={columnsTV}
            dataSource={DataUser?.THANH_VIEN?.object?.map(item => ({
              ...item,
              key: item.id,
            }))}
            sticky={{ offsetHeader: -14 }}
            scroll={{ x: "800px" }}
            style={{ marginBottom: 4 }}
            expandable={{
              expandedRowRender,
              rowExpandable: record =>
                record.children && record.children.length > 0,
              // expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
              // onExpand: handleExpand,
            }}
          />
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div className="d-flex label-title">
          <div className="icon-ds">
            <SvgIcon name="judges" />
          </div>
          <div className="ml-5 text-step">Ban giám khảo</div>
        </div>
      ),
      children: (
        <div>
          <TableCustom
            loading={loading}
            isPrimary
            columns={columns}
            dataSource={DataUser?.BAN_GIAM_KHA0?.object?.map(item => ({
              ...item,
              key: item.id,
            }))}
            sticky={{ offsetHeader: -14 }}
            scroll={{ x: "800px" }}
            style={{ marginBottom: 4 }}
            expandable={{
              expandedRowRender,
              rowExpandable: record =>
                record.children && record.children.length > 0,
              // expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
              // onExpand: handleExpand,
            }}
          />
        </div>
      ),
    },
  ]

  const onChange = key => {
    console.log(key)
  }
  const handleDownload = record => {
    const link = document.createElement("a")
    link.href = record?.commit_url
    link.target = "_blank"
    link.download = "" // You can specify a filename here if you want
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Styled>
      <Row gutter={[10, 15]}>
        <Col span={24}>
          <Row gutter={[10]}>
            <Col span={14}>
              <StyleSearch>
                <Search
                  placeholder="Nhập họ tên tài khoản"
                  allowClear
                  onSearch={value => {
                    setPagination({
                      ...pagination,
                      input: value,
                    })
                  }}
                />
              </StyleSearch>
            </Col>
            <Col span={5}>
              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                allowClear
                placeholder="Quyền"
                optionFilterProp="children"
                onChange={value => {
                  setPagination({
                    ...pagination,
                    role_id: value,
                  })
                }}
                options={listPractise}
              />
            </Col>
            <Col span={5}>
              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                allowClear
                placeholder="Vai trò"
                optionFilterProp="children"
                onChange={value => {
                  setPagination({
                    ...pagination,
                    position_id: value,
                  })
                }}
                options={listRole}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="justify-content-space-between">
            <Col className="d-flex" style={{ alignItems: "center" }}>
              <div className="Text-title">
                <h2>
                  {organizers
                    ? "Danh sách ban tổ chức"
                    : "Danh sách tham gia diễn tập"}
                </h2>
              </div>
            </Col>
            <Col>
              <Space>
                {/* <Button
                    btnType="primary"
                    // onClick={() => setOpenModal(true)}
                  >
                    Copy Link mời
                  </Button> */}
                {button?.btn_AddUser && (
                  <Button
                    btnType="primary"
                    onClick={() => {
                      setOpenModal({
                        isdata: false,
                      })
                      setIsRegister(false)
                    }}
                  >
                    + Thêm người
                  </Button>
                )}

                {!organizers && (
                  <>
                    {button?.btn_AddTeam && (
                      <Button
                        btnType="primary"
                        onClick={() => {
                          setOpenModalTeam(listRole)
                          setIsRegister(false)
                        }}
                      >
                        + Thêm đội
                      </Button>
                    )}
                  </>
                )}
                {button?.btn_ImportFile && (
                  <Button
                    btnType="primary"
                    onClick={() => setOpenModalPeople(1)}
                  >
                    Nhập file
                  </Button>
                )}
                {button?.btn_ImportFile && (
                  <Button
                    btnType="primary"
                    onClick={() => setOpenModalPeople(2)}
                  >
                    Thêm đội bằng file
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Collapse
            items={organizers ? [items[0]] : items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </Col>
      </Row>
      {openModel && (
        <NewUserModal
          open={openModel}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          getData={getData}
          listPractise={listPractise}
        />
      )}
      {openModelPeople && (
        <PeopleAdd
          open={openModelPeople}
          onOk={() => setOpenModalPeople(false)}
          onCancel={() => setOpenModalPeople(false)}
          getData={getData}
        />
      )}
      {openModelTeam && (
        <TeamAdd
          open={openModelTeam}
          onOk={() => setOpenModalTeam(false)}
          onCancel={() => setOpenModalTeam(false)}
          getData={getData}
          listPractise={listPractise}
        />
      )}
    </Styled>
  )
}

export default UserJoin
