import {
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import { FormDetailWrapper } from "./styles"
import AuthService from "src/services/AuthService"

const ModalRoleGroupForm = ({ visible, onCancel, onOk, dataInfo, edit }) => {
  const [form] = Form.useForm()
  const { listSystemKey } = useSelector(state => state.appGlobal)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState()
  const [userToRole, setUserToRole] = useState([])
  const [options1, setOptions1] = useState([])

  const fetchData = async () => {
    setLoading(true)
    try {
      const res = await AuthService.getCategoriesIncludeRoles()
      if (res.isOk) {
        const options = res?.data?.map((category, index) => ({
          key: index.toString(),
          value: category.id,
          label: category.description,
          options: category.roles
            ? category.roles.map(role => ({
                options: role.description,
                value: role.id,
              }))
            : [],
        }))
        setOptions1(options)
      }
    } finally {
      setLoading(false)
    }
  }
  const getUserToRole = async () => {
    setLoading(true)
    try {
      const res = await AuthService.getUserToRole({})
      if (res.isOk) {
        setUserToRole(res)
      }
    } finally {
      setLoading(false)
    }
  }
  const CreateRoleGroup = async () => {
    setLoading(true)
    try {
      const values = await form?.validateFields()

      // Sử dụng điều kiện ngắn gọn &&
      const res =
        edit == true
          ? await AuthService.updateRoleGroup({
              role_group_id: dataInfo.id,
              role_name: values.role_name,
              status: values.status,
              users: values.users,
              roles: [],
              ...values,
            })
          : await AuthService.createRoleGroup({
              role_name: values.role_name,
              status: values.status,
              users: values.users,
              roles: [],
              ...values,
            })

      if (res.isOk) {
        onOk()
        Notice({ msg: res.message, isSuccess: true })
      }
    } catch (error) {
      Notice({ msg: error.message, isSuccess: false })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    getUserToRole()
  }, [])

  return (
    <Spin loading={loading}>
      <Modal
        title={dataInfo?.id ? `Cập nhật nhóm quyền` : "Tạo nhóm quyền"}
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        width={1200}
        style={{ top: 20 }}
        bodyStyle={{
          maxHeight: "calc(300vh - 10px)",
          overflow: "auto",
        }}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Space>
              <Button
                btnType="primary"
                className="btn-hover-shadow"
                type="submit"
                onClick={CreateRoleGroup}
              >
                Ghi lại
              </Button>
            </Space>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={
            {
              // Username: userInfo.Username,
            }
          }
        >
          <SpinCustom spinning={loading}>
            <FormDetailWrapper form={form} layout="vertical">
              <Row gutter={16}>
                <Col span={18}>
                  <Form.Item
                    label="Tên nhóm quyền"
                    name="role_name"
                    rules={[
                      {
                        required: true,
                        message: "Tên nhóm quyền không được để trống",
                      },
                    ]}
                  >
                    <Input allowClear placeholder="Nhập tên nhóm quyền" />
                  </Form.Item>
                </Col>
                {/* {dataInfo?.id && ( */}
                <Col span={6}>
                  <Form.Item name="status" label="Trạng thái">
                    <Select placeholder="chọn trạng thái">
                      <Select.Option value={1} key={1}>
                        Đang hoạt động
                      </Select.Option>
                      <Select.Option value={0} key={0}>
                        Không hoạt động
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* )} */}

                <Col span={24}>
                  <Form.Item
                    label="Loại nhóm quyền"
                    name="roles_type"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Loại nhóm quyền không được để trống",
                    //   },
                    // ]}
                  >
                    <Radio.Group value={value}>
                      <Radio value={1}>System admin</Radio>
                      <Radio value={2}>Cá nhân</Radio>
                      <Radio value={3}>Doanh nghiệp/Tổ Chức</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Chọn quyền" name="roles">
                    <Checkbox.Group style={{ display: "flex" }}>
                      {options1 &&
                        options1.length > 0 &&
                        options1.map(
                          option =>
                            option &&
                            option.options &&
                            option.options.length > 0 && (
                              <div
                                key={option.label}
                                style={{
                                  backgroundColor: "#f0f6ff",
                                  width: "100%",
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                }}
                              >
                                <div style={{ padding: "8px" }}>
                                  <div style={{ fontWeight: "600" }}>
                                    {option.label}:
                                  </div>
                                  {option.options.map(
                                    opt =>
                                      opt && (
                                        <Checkbox
                                          key={opt.value}
                                          value={opt.value}
                                          style={{ margin: "8px 12px" }}
                                        >
                                          {opt.options}
                                        </Checkbox>
                                      ),
                                  )}
                                </div>
                              </div>
                            ),
                        )}
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
              <Form.Item label="Chọn quyền" name="roles">
                <Checkbox.Group style={{ width: "100%" }}>
                  {options1.map((option) =>
                    option.options.map((opt) => (
                      <Checkbox key={opt.value} value={opt.value}>
                        {opt.options}
                      </Checkbox>
                    ))
                  )}
                </Checkbox.Group>
              </Form.Item>
            </Col> */}

                <Col span={24}>
                  <Form.Item label="Người dùng" name="users">
                    <Select mode="multiple">
                      {userToRole?.data?.users &&
                        userToRole.data.users.map(user => (
                          <Select.Option key={user.id} value={user.fullname}>
                            {user.fullname}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </FormDetailWrapper>
          </SpinCustom>
        </Form>
      </Modal>
    </Spin>
  )
}
export default ModalRoleGroupForm
