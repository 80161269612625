import {
  apiGetAllChidrenByRegionId,
  apiInsertRegion,
  apiUpdateRegion,
  apiDeleteRegion,
  apiGetLocationVN,
  apiGetByRegionId,
  apiGetAllRegions,
  apiGetListDistrict,
  apiGetListProvince,
  apiGetListwards,
} from "./urls"
import http from "../index"

const getAllChidrenByRegionId = params =>
  http.get(apiGetAllChidrenByRegionId, { params })
const getLocationVN = params => http.get(apiGetLocationVN, { params })
const insertRegion = body => http.post(apiInsertRegion, body)
const updateRegion = body => http.put(apiUpdateRegion, body)
const deleteRegion = params => http.patch(apiDeleteRegion + params)
const getByRegionId = params => http.get(apiGetByRegionId, { params })
const GetAllRegions = params => http.get(apiGetAllRegions, { params })
// tỉnh thành phố
const getListDistrict = params => http.get(apiGetListDistrict, { params })
const getListProvince = params => http.get(apiGetListProvince, { params })
const getListwards = params => http.get(apiGetListwards, { params })

const RegionService = {
  getListwards,
  getListProvince,
  getListDistrict,
  getAllChidrenByRegionId,
  insertRegion,
  updateRegion,
  deleteRegion,
  getLocationVN,
  getByRegionId,
  GetAllRegions,
}
export default RegionService
