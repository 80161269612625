import { Col, Form, Row, Space, Spin, Tooltip, Upload } from "antd"
import JSZip from "jszip"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import CB1 from "src/components/Modal/CB1"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SvgIcon from "src/components/SvgIcon"
import TableCustom from "src/components/Table/CustomTable"
import { MainTableData } from "src/components/Table/CustomTable/styled"
import STORAGE, { getStorage } from "src/lib/storage"
import { normFile } from "src/lib/utils"
import FileService from "src/services/FileService"
import PractiseService from "src/services/PractiseService"
import styled from "styled-components"
import { saveAs } from "file-saver"

const StyleIcon = styled.div`
  .ant-upload-wrapper {
    background-color: white;
    border-radius: 50%;
    padding: 5px 4px;
  }
`

const Plan = ({ formDetail }) => {
  const userInfo = getStorage(STORAGE.USER_INFO)
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    PageSize: 50,
    CurrentPage: 1,
  })
  const [button, setButtonData] = useState()
  const [dataSource, setDataSource] = useState([])
  const getListplan = async () => {
    try {
      setLoading(true)
      const res = await PractiseService.getDeployPlan({
        practise_id: id,
      })
      if (res.isOk) {
        setDataSource(res?.data)
        setButtonData(res?.button)
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getListplan()
  }, [])

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 60,
      render: (val, record, idx) => (
        <div className="text-center">
          {idx + 1 + pagination.PageSize * (pagination.CurrentPage - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Tên file",
      dataIndex: "title",
      key: "title",
      render: (val, record, idx) => (
        <div className="text-center">{record?.allcode?.value}</div>
      ),
      width: 300,
    },
    {
      title: "Thời gian nộp",
      dataIndex: "deadline",
      key: "deadline",
      align: "center",
    },

    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (val, record) => (
        <Tooltip title={<div>{record?.file_name}</div>}>
          <MainTableData>
            <a href={record.url} target="_blank">
              {record?.file_name}
            </a>
          </MainTableData>
        </Tooltip>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 130,
      align: "center",
      render: (val, record) => (
        <StyleIcon>
          <div>
            {record?.status === 1 ? (
              "Chưa tiếp nhận"
            ) : record?.status === 0 ? (
              <div style={{ color: "blue", fontWeight: "600" }}>
                Đã tiếp nhận
              </div>
            ) : (
              <div style={{ color: "red", fontWeight: "600" }}>
                {val == null ? "" : "Từ chối"}
              </div>
            )}
          </div>
          <FloatActionWrapper size="small" className="float-action__wrapper">
            {!!formDetail ? (
              <Space>
                {record?.url && (
                  <>
                    {button?.btn_Dowload && (
                      <ButtonCircle
                        title="Dowload"
                        iconName="dowload-export"
                        onClick={() => handleDownload(record)}
                      />
                    )}
                  </>
                )}
              </Space>
            ) : (
              <Space>
                {!record?.url ? (
                  <Upload
                    style={{
                      borderRadius: "43% !important",
                      backgroundColor: "white !important",
                      padding: "4px !important",
                    }}
                    className="d-flex align-items-center"
                    beforeUpload={file => {
                      uploadImg(file, record)
                      return false
                    }}
                    accept="image/*"
                    multiple={false}
                    maxCount={1}
                    fileList={[]}
                  >
                    <div className="upload-avatar">
                      <div className="d-flex justify-content-center">
                        <Tooltip title="Tải file">
                          <div className="sign-background-upload d-flex align-items-center">
                            {/* <ButtonCircle iconName="import-cloud" /> */}
                            <SvgIcon name="cloud-upload" />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </Upload>
                ) : (
                  <>
                    {button?.btn_Dowload && (
                      <ButtonCircle
                        title="Dowload"
                        iconName="dowload-export"
                        onClick={() => handleDownload(record)}
                      />
                    )}
                    {button?.btn_Delete && (
                      <ButtonCircle
                        title="Xoá"
                        iconName="delete-outline"
                        onClick={() =>
                          CB1({
                            title: `Bạn có chắc chắn muốn xóa "${record?.deadline}" ?`,
                            icon: "trashRed",
                            okText: "Đồng ý",
                            onOk: () => {
                              setLoading(true)
                              PractiseService.deleteDeployPlan({
                                practise_id: id,
                                plan_id: record?.plan_id,
                              })
                                .then(res => {
                                  if (res?.isOk) {
                                    getListplan()
                                    Notice({
                                      msg: "Xóa thành công!",
                                    })
                                  }
                                })
                                .finally(() => setLoading(false))
                            },
                          })
                        }
                      />
                    )}
                  </>
                )}
              </Space>
            )}
          </FloatActionWrapper>
        </StyleIcon>
      ),
    },
  ]
  const handleDownload = async record => {
    try {
      setLoading(true)
      // Tải file từ URL

      // Tạo đối tượng JSZip
      const zip = new JSZip()
      // Thêm file vào ZIP
      zip.file(record?.file_name, record?.url)

      // Tạo file ZIP và tải xuống
      const zipBlob = await zip.generateAsync({ type: "blob" })
      saveAs(zipBlob, "Tailieu.zip")
    } catch (error) {
      console.error("Error downloading or zipping file:", error)
    } finally {
      setLoading(false)
    }
  }
  const uploadImg = async (file, record) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("file_list", file)
      formData.append("practise_id", id)
      const res = await FileService.uploadFile(formData)
      if (res.isError) return
      if (res.isOk) {
        const resp = await PractiseService.addDeployPlan({
          practise_id: id,
          user_id: userInfo?.id,
          plan_id: record?.plan_id,
          docs: res?.data[0],
        })
        if (resp.isOk) {
          getListplan()
        }
      }
      // setAvatarUpload(res.data[0])
    } finally {
      setLoading(false)
    }
  }
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <h2 className="title-s3">Phương án triển khai</h2>
        </Col>
        <Col span={24} className="text-s3">
          <div className="text-g2">Lưu ý</div>
          <div>
            <ul>
              <li>
                - Các tài liệu có thời hạn nộp nếu quá thời hạn nộp chưa bổ sung
                tài liệu thì diễn tập sẽ tự động bị hủy bỏ
              </li>
              <li>
                - Diễn tập chỉ được phép diễn ra khi phương án triển khai diễn
                tập được phê duyệt
              </li>
            </ul>
          </div>
        </Col>
        <Col span={24}>
          <TableCustom
            // loading={loading}
            isPrimary
            columns={columns}
            dataSource={dataSource}
            sticky={{ offsetHeader: -14 }}
            scroll={{ x: "800px" }}
            style={{ marginBottom: 4 }}
            showPagination
            footerLeft={<div className="d-flex mt-20" />}
            widthScroll={1200}
            textEmpty="Không có dữ liệu!"
          />
        </Col>
      </Row>
    </Spin>
  )
}

export default Plan
