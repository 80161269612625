import styled from "styled-components"

export const StyleBookUse = styled.div`

    .tree-col{
        /* width: 22%; */
        padding: 10px;
    }
    .tree-style{
        border: 1px solid #e5e2e2;
        overflow-y: scroll;
        border-radius: 12px;
        height: 77vh;
    }
    .ant-tabs-nav{
        margin: 0px;
    }
`
export const StyleSearch = styled.div`
button.ant-btn.ant-input-search-button{
    height: 39px;
    width: 39px;
}
`