// Role
export const apiGetByRoleId = `Role/GetByRoleId`
export const apiCreateOrUpdateRole = `Role/CreateOrUpdateRole`
export const apiGetAllForCombobox = `Role/GetAllForCombobox`
export const apiGetListTab = `Role/GetListTab`
export const apiGetListTask = `Role/GetListTask`
export const apiChangeStatus = `Role/ChangeStatus`



export const apiGetListRole = `/chuyendoiso/api/v1/GetRoleGroups`
export const apiDeleteRoleGroupByID = `/chuyendoiso/api/v1/DeleteRoleGroupByID`

