import styled from "styled-components"
import RectangleImg from "../../../assets/images/RehearsalLogo/Rectangle.png"
import imgBack from "src/assets/images/logo/detail-back.png"
import box from "src/assets/images/logo/box-detail.png"
import comming from "src/assets/images/logo/comming.png"

export const UploadStyle = styled.div`
  .ant-upload.ant-upload-select {
    line-height: 20px;
    border-radius: 11px;
    border: 1px dashed gray !important;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100% !important;
    cursor: pointer;
    transition: border-color var(--ant-motion-duration-slow);
    background-color: aliceblue !important;
  }
  .ant-upload-drag:hover {
    border-color: #4096ff;
  }
`

export const RehearsalManagementStyle = styled.div`
  .demo-editor {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 275px !important;
    padding: 5px;
  }
  .ant-card-bordered {
    border: 0;
  }
  .ant-card-bordered .ant-card-bordered .ant-card-cover {
    margin-top: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
  .ant-card .ant-card-cover img {
    border-radius: 20px;
    object-fit: cover;
  }
  .title-h1 {
    font-size: 45px;
    margin-bottom: 0px;
  }
  .title-p {
    align-items: center;
    display: flex;
    text-transform: uppercase;
    color: #00549a;
    font-size: 15px;
    font-weight: 600;
  }
  .ant-card-body {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 7px;
    padding-right: 7px;
  }
  .ant-card-hoverable {
    border-radius: 20px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1),
      0 0 30px rgba(0, 0, 0, 0.1);
  }

  .ant-card-hoverable:hover {
    border-color: rgb(8, 134, 197);
    box-shadow: rgba(58, 147, 212, 0.5) 0px 0px 15px,
      rgba(32, 140, 220, 0.4) 0px 0px 17px, rgba(51, 141, 207, 0.3) 0px 0px 25px;
  }
`
export const NewFormStyle = styled.div`
  .demo-editor {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 275px !important;
    padding: 5px;
  }
`
export const StyleFormModal = styled.div`
  /* .paddingBottom0.ant-form-item .ant-form-item-label {
    padding-bottom: 0 0 8px !important;
} */
  .ant-radio-wrapper {
    padding: 0px 30px !important;
  }
  .demo-editor {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 150px !important;
    padding: 5px;
  }

  .RadiosHead .ant-form-item .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    display: flex !important;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
  }
  .ant-select-single {
    width: 100% !important;
  }
`
export const StyleFileBM01 = styled.div`
  .ant-collapse-item {
    background: white;
    border: none;
  }
  .tile-01 {
    color: #2260bd;
    text-transform: uppercase;
  }
  .ant-collapse.ant-collapse-icon-position-start {
    border: none;
  }
  span.ant-collapse-header-text {
    font-weight: 700;
  }
  .ant-radio-wrapper {
    padding: 0px 30px !important;
  }
  .demo-editor {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 150px !important;
    padding: 5px;
  }

  .RadiosHead .ant-form-item .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    display: flex !important;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
  }
  .ant-select-single {
    width: 100% !important;
  }
`
export const StyleFileBM02 = styled.div`
  .tile-01 {
    color: #2260bd;
    text-transform: uppercase;
  }
  .ant-checkbox-group {
    width: 100%;
    justify-content: space-between;
  }
  .ant-radio-group {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
  }

  .ant-radio-wrapper {
    padding: 0px 30px !important;
  }
  .demo-editor {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 150px !important;
    padding: 5px;
  }
  /* .ant-form-item .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    display: flex !important;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
  } */
  .ant-select-single {
    width: 100% !important;
  }
  .boxSoilBottom {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 5px;
  }
`
export const DetailBack = styled.div`
  .ant-table-wrapper .ant-table-sticky-holder {
    position: unset;
  }
  .list-detail.ml-24 {
    color: white;
  }
  .ant-table-wrapper .ant-table-row-expand-icon-collapsed::after {
    color: black;
  }
  .ant-table-wrapper .ant-table-row-expand-icon-collapsed::before {
    color: black;
  }
  /* css table detail */
  .ant-collapse-expand-icon {
    display: none !important;
  }
  .icon-ds {
    display: block !important;
  }
  .label-title {
    align-items: center;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #ffffff1a !important;
  }
  .ant-table-header.ant-table-sticky-holder {
    background: transparent !important;
  }
  .ant-table-thead .ant-table-cell {
    background: transparent !important;
  }
  .ant-table-wrapper .ant-table {
    background: transparent !important;
    color: white;
    font-weight: 700;
  }
  .ant-collapse-header {
    background: #ffffff1a !important;
    color: white !important;
    font-weight: 700;
  }
  .ant-collapse-content-active {
    background-color: transparent;
  }
  .th-close {
    border-radius: 12px;
    color: black;
    font-weight: 700;
    background: #f8db46;
  }
  .text-ranking {
    font-size: 2vw;
    font-weight: 700;
    text-align: center;
    margin: 20px;
  }
  .title-ranking {
    color: white;
    font-size: 2.5vw;
    font-weight: 700;
    text-align: center;
    margin: 30px;
  }
  .table-close {
    width: 80%;
    margin: auto;
    line-height: 3.4;
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  .row-close:nth-child(odd) {
    background: #ffffff1a;
  }
  .row-close td {
    text-align: center;
  }
  .button-search-comming {
    font-size: 21px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .img-comming {
    background-image: url(${comming});
    width: 100%;
    height: 100vh;
    background-size: cover;
  }

  .title-text-comming {
    color: white;
    margin: auto;
    width: 80%;
  }
  .button-setting-comming {
    background: linear-gradient(90deg, #3175ff 0%, #b31952 100%);
    border: none;
  }
  .title-text-comming p {
    margin-bottom: 25px;
    text-align: center;
    width: 80%;
    font-weight: 700;
    font-size: 1.5vw;
  }
  .time-comming {
    position: absolute;
    width: 100%;
    top: 15%;
    /* left: 36%; */
    display: flex;
    justify-content: center;
  }
  .text-comming {
    width: 100%;
    position: absolute;
    top: 32%;
    text-align: center;
    color: white;
  }
  .text-comming h1 {
    margin-top: 20px;
    margin-bottom: 29px;
    font-size: 7vw;
    font-weight: 700;
  }
  .comming {
    width: 100%;
    height: 100vh;
  }
  .hoverable-td {
    cursor: pointer; /* Thay đổi con trỏ thành hình bàn tay */
    transition: background-color 0.3s ease; /* Thêm hiệu ứng chuyển đổi mượt mà */
  }

  .hoverable-td:hover {
    background-color: rgb(241 237 237 / 10%); /* Làm sáng phần tử khi hover */
  }
  .ant-form-item-required {
    color: white !important;
  }
  .total-group {
    color: white;
  }
  .Text-title {
    color: white !important;
  }
  background: linear-gradient(180deg, #3f0a16 0%, #000859 100%);
  height: 100%; /* Đảm bảo nền bao phủ toàn bộ chiều cao màn hình */
  margin: 0; /* Loại bỏ khoảng cách mặc định */
  .img-backgrou-detail {
    /* background-image: url(${imgBack}); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-position: center; */
    /* background-size: contain; */
    /* height: 70vh; */
    /* height: 390px; */
    display: flex;
    justify-content: center;
  }
  .img-img {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
  }
  .title {
    font-family: Saira Stencil One;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
  }
  .box-detail {
    /* overflow: hidden;
    background-image: url(${box});
    background-repeat: no-repeat;
    background-size: contain; */
    /* height: 135vh; */
    width: 100%;
  }
  .detail-img-style {
    margin: auto;
  }
  .box-detail-img {
    margin: auto;
  }
  .text-end {
    font-size: 33px;
    color: #7a7a7a !important;
    position: relative;
    font-weight: 700;
    text-align: end;
    bottom: 100%;
    /* top: 55px; */
  }
  .ant-steps-item-icon {
    display: none;
  }
  .ant-steps-item-title {
    padding-left: 10px;
  }
  .box-detail-img {
    width: 95% !important;
  }
  .time-comming .box-characters {
    visibility: hidden;
  }
  .time-comming .time-out {
    position: unset;
  }
  .time-comming .box-time {
    color: white;
    border-radius: 12px;
    background: #ffffff33;
    padding: 1.2vw 2.3vw;
  }
  .time-comming .box-one-time {
    color: white;
    font-size: 3.4vw;
    background: none;
  }
  @media (max-width: 1366px) {
    .detail-img-style {
      width: 1300px !important;
    }
    /* .box-detail-img {
      width: 1300px !important;
    } */
    .detail-dt {
      top: 130px !important;
    }
    .box-three {
      width: 280px !important;
      height: 240px !important;
    }
    .text-end {
      right: 6% !important;
      /* top: -45px !important; */
    }
    .time-out {
      left: 33% !important;
    }
    .right-tab {
      height: 77vh !important;
    }
    .img-size {
      margin-top: 20px !important;
    }
    /* .time-comming .box-one-time {
      font-size: 77px !important;
    } */
  }
  @media (max-width: 1600px) {
  }
  @media (max-width: 1920px) {
    /* .time-comming .box-one-time {
      font-size: 92px;
    } */
    .detail-img-style {
      width: 1770px;
    }
    /* .box-detail-img {
      width: 1770px;
    } */
    .detail-dt {
      top: 188px;
    }
    .box-three {
      width: 340px;
      height: 280px;
    }
    .text-end {
      right: 12%;
      /* bottom: 19vh; */
    }
    .time-out {
      left: 36%;
    }
    .right-tab {
      height: 74vh;
    }
    .img-size {
      margin-top: 50px;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    border-radius: 3px 0px;
    background: white;
  }
  .tile-01 {
    color: white;
  }
  .title-font {
    font-size: 24px;
  }
  .child-tabs {
    padding-left: 20px;
  }
  /* css step */
  .ant-steps.ant-steps-vertical > .ant-steps-item {
    display: block;
    flex: 0.02 0 auto !important;
    padding-inline-start: 0;
    overflow: visible;
  }
  .field-title {
    color: white;
  }
  .ant-steps-item-content {
    display: flex !important;
    align-items: center;
  }
  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: 40px !important;
  }
  .ant-steps-item-active {
    border-radius: 15px;
    height: 35px;
    background-color: #eaf5ff;
    display: flex !important;
    width: 100%;
    align-items: center;
  }
  .ant-steps-item-active .text-tile-tabs {
    color: blue;
  }
  .ant-steps-item-icon {
    visibility: hidden;
  }
  .ant-steps-item-tail {
    visibility: hidden;
  }
  .text-tile-tabs {
    font-weight: 700;
  }
  .user-avatar {
    height: 100% !important;
    width: 100% !important;
    border-radius: 0px !important;
  }
  /* end css step */
`
export const TableStyle = styled.div`
  table,
  th,
  td {
    border-radius: 10px;
    padding: 8px;
    font-size: 20px;
    border: 1px solid #9a9fb6;
  }
  th {
    background-color: #555353;
  }
  table {
    width: 100%;
  }
`
export const DetailReStyle = styled.div`
  .time-out {
    position: absolute;
  }
  .box-time {
    margin-right: 10px;
  }
  .text-time {
    text-align: center;
    font-size: 20px;
  }
  .box-characters {
    color: #eaf5ff;
    font-size: 53px;
    margin-right: 8px;
  }
  .box-one-time {
    padding: 0 8px;
    color: #29a2ec;
    font-size: 53px;
    border-radius: 10px;
    background-color: #eaf5ff;
  }

  .box-three {
    background: linear-gradient(to right, #000859 0%, #3f0a16 100%);
    position: relative;
    padding: 25px 30px;
    border-radius: 10px;
    overflow: hidden; /* Đảm bảo nội dung không vượt ra ngoài */
  }
  .box-three::before {
    content: "";
    position: absolute;
    top: -10px; /* Dịch chuyển pseudo-element ra ngoài */
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-radius: 50%;
    border: 15px solid transparent; /* Độ dày viền và thiết lập viền trong suốt */
    z-index: 0; /* Đặt pseudo-element phía sau nội dung */
    border-image: linear-gradient(to right, #01cafc, #f3483d) 1;
  }
  .left-tab {
    /* background-color: transparent; */
    border-radius: 10px 0 0 10px;
  }

  .right-tab {
    border-radius: 0 0 0px 0;
    padding: 20px 20px;
    background-color: transparent;
    /* height: 84vh; */
    overflow-x: hidden;
    overflow-y: auto;
  }
  .text-tile-tabs {
    color: white;
  }
  /* khung chi tiết */
  .detail-dt {
    left: 0px;
    position: absolute;
    /* border: 1px solid white; */
    padding: 22px 0px;
    width: 97%;
    /* height: 60vh; */
    /* background-color: #1e6dda; */
  }

  .box-left-scroll {
    height: 100vh;
    overflow-x: auto;
    width: 85%;
    /* height: 500px; */
  }
  .text-g2 {
    font-weight: 700;
  }
  .text-s3 {
    color: red;
    font-size: 15px;
  }
  h2.title-s3 {
    color: #2260bd;
  }
  .d-flex.align-items-center.justify-content-space-between.right svg {
    transform: rotate(180deg);
  }
  .button-details:hover {
    cursor: pointer;
    color: rgb(110 158 197) !important;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    margin-right: 4px;
    border-radius: 7px !important;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #4267ab;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }
`
export const StyleRehearsalRules = styled.div``
export const StyleFileBM03 = styled.div`
  .ant-radio-wrapper {
    padding: 0px 30px !important;
  }
  .demo-editor {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 150px !important;
    padding: 5px;
  }

  .RadiosHead .ant-form-item .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    display: flex !important;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
  }
  .ant-select-single {
    width: 100% !important;
  }
`
export const ModalOpeningStyle = styled.div`
  .ContenTextClose {
    padding-top: 10px;
    height: 160px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 32px;
  }
  .ContenText {
    padding-top: 10px;
    height: 160px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .ContenText1 {
    font-weight: 700;
    font-size: 32px;
    display: flex;
    justify-content: center;
    padding-top: 16px;
    color: #ffffff;
  }
  .boxButton {
    padding-top: 24px;
    /* justify-content: center; */
  }

  .boxOpening {
    background-image: url(${RectangleImg});
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position: center;
    height: 390px;
  }
  .boxText {
    /* background-color: red; */
    height: 100%;
    /* padding-left: 20px; */
    margin-left: 9px;
  }
  .boxLogoIcon {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .BoxTexOpening {
    /* height: 390px; */
    justify-content: center;
    display: flex;
    /* padding-top: 20px; */
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    color: #ffffff;
  }
  .ContenText {
    font-size: 32px;
  }
  .texDate {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 20px;
  }
  .iconDate {
    padding-top: 8px;
  }
`
export const StyleButoonCustom = styled.div`
  /* .buttonWrapper {
  border-radius  : 6px;
  display        : flex !important;
  align-items    : center;
  font-weight    : 500;
  line-height    : 10px;
  justify-content: end;
  height         : 37px !important;
  padding-right  : 8px; 

  .anticon {
    margin-left: 4px; 
  }

  .name {
    font-weight: 700;
    line-height: 14px;
  }
}
.styles_buttonWrapper{
    justify-content: end;
} */
  .button {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 0.5rem;
    padding-inline: 1.25rem;

    /* background-color: rgb(0 107 179); */
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
  }

  .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
  }

  .button:hover {
    transform: scale(1.05);
    border-color: #fff9;
  }

  .button:hover .icon {
    transform: translate(4px);
  }

  .button:hover::before {
    animation: shine 1.5s ease-out infinite;
  }

  .button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }

  @keyframes shine {
    0% {
      left: -100px;
    }

    60% {
      left: 100%;
    }

    to {
      left: 100%;
    }
  }
`
export const ButtonIconStyle = styled.div`
  .button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .button .bloom-container {
    position: relative;
    /* transition: all 0.1s ease-in-out; */
    border: none;
    background: none;
  }

  .button .bloom-container .button-container-main {
    width: 55px;
    height: 55px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: grid;
    place-content: center;
    border-right: 5px solid white;
    border-left: 5px solid rgba(128, 128, 128, 0.147);
    transform: rotate(-45deg);
    /* transition: all 0.25s ease-in-out; */
  }

  .button .bloom-container .button-container-main .button-inner {
    /* height: 60px; */
    height: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    position: relative;
    box-shadow: rgba(100, 100, 111, 0.5) -10px 5px 10px 0px;
    transition: all 0.25s ease-in-out;
  }

  .button .bloom-container .button-container-main .button-inner .back {
    position: absolute;
    inset: 0;
    border-radius: 50%;
    /* background: linear-gradient(60deg, rgb(1, 85, 103) 0%, rgb(147, 245, 255) 100%); */
  }

  .button .bloom-container .button-container-main .button-inner .front {
    transform: rotate(45deg);
    position: absolute;
    inset: 5px;
    border-radius: 50%;
    /* background: linear-gradient(60deg, rgb(0, 103, 140) 0%, rgb(58, 209, 233) 100%); */
    display: grid;
    place-content: center;
  }

  .button .bloom-container .button-container-main .button-inner .front .svg {
    fill: #ffffff;
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-in;
    /* filter: drop-shadow(0 0 10px white); */
  }

  .button .bloom-container .button-container-main .button-glass {
    /* position: absolute;
  inset: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.888) 100%);
  transform: translate(0%, -50%) rotate(0deg);
  transform-origin: bottom center;
  transition: all 0.25s ease-in-out; */
  }

  .button .bloom-container .bloom {
    /* height: 1px;
  width: 1px;
  position: absolute;
  background: white; */
  }

  .button .bloom-container .bloom1 {
    /* top: 10px;
  right: 20px;
  box-shadow: rgb(255, 255, 255) 0px 0px 10px 10px, rgb(255, 255, 255) 0px 0px 20px 20px; */
  }

  .button .bloom-container .bloom2 {
    /* bottom: 10px;
  left: 20px;
  box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 10px 10px, rgba(255, 255, 255, 0.5) 0px 0px 30px 20px; */
  }

  .button .bloom-container:hover {
    transform: scale(1.1);
  }

  .button .bloom-container:hover .button-container-main .button-glass {
    /* transform: translate(0%, -40%); */
  }

  .button
    .bloom-container:hover
    .button-container-main
    .button-inner
    .front
    .svg {
    opacity: 1;
    filter: drop-shadow(0 0 10px white);
  }

  .button .bloom-container:active {
    transform: scale(0.7);
  }

  .button .bloom-container:active .button-container-main .button-inner {
    transform: scale(1.2);
  }
`

export const IConCustomTonggerStyle = styled.div`
  .wrapper {
    display: flex;
    list-style: none;
    width: 100%;
    font-family: "Poppins", sans-serif;
    flex-wrap: wrap;
  }

  .wrapper .icon {
    position: relative;
    background: #fff;
    border-radius: 50%;
    margin: 5px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .wrapper .tooltip {
    width: max-content;
    position: absolute;
    top: 0;
    font-size: 14px;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .wrapper .icon:hover {
    background: rgb(176 230 214);
    z-index: 99;
  }
  .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: var(--tooltip-before-bg-color);
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  .wrapper .icon:hover span,
  .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    background: var(--tooltip-bg-color);
  }

  .wrapper .icon:hover .tooltip::before {
    --tooltip-before-bg-color-hover: var(
      --tooltip-before-bg-color
    ); /* Sử dụng màu nền của tooltip cho pseudo-element khi hover */
  }

  .wrapper .facebook:hover,
  .wrapper .facebook:hover .tooltip,
  .wrapper .facebook:hover .tooltip::before {
    --tooltip-bg-color: #1877f2; /* Màu nền của tooltip */
    --tooltip-before-bg-color: #a825f9; /* Màu nền của ::before */
    color: #fff;
  }
`
