import styled from "styled-components"

export const StyleSunEditor = styled.div`
  /* customSunEditor.css */

  .sun-editor {
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .sun-editor .se-toolbar {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    border-radius: 8px 8px 0 0;
  }

  .sun-editor .se-toolbar .se-btn {
    color: #333;
  }

  .sun-editor .se-toolbar .se-btn:hover {
    background-color: #f0f0f0;
  }

  .sun-editor .se-wrapper {
    background-color: #fafafa;
    border-radius: 0 0 8px 8px;
  }

  .sun-editor .se-container {
    border-radius: 8px;
  }
  .sun-editor .se-btn-module-border {
    border: none;
  }
  .sun-editor .se-toolbar {
    border-bottom: transparent;
  }
  .sun-editor .se-toolbar .se-btn:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
    color: #007bff; /* Màu xanh khi hover */
  }
`
