import { Col, Modal, Row, Space } from "antd"
import React from "react"
import Button from "src/components/MyButton/Button"
import { StyleDetails } from "./styled"

const DetailsReport = ({ open, onOk, onCancel, getData }) => {
  console.log(open)
  return (
    <div>
      <Modal
        title={"Chi tiết Báo cáo"}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              // onClick={() => createForm()}
            >
              Nhận
            </Button>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              // onClick={() => createForm()}
            >
              Từ chối
            </Button>
          </Space>
        }
      >
        <StyleDetails>
          <div className="box">
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <div className="title-text">
                  <h4>Thông tin báo cáo</h4>
                </div>
              </Col>
              <Col span={8} className="d-flex">
                <div className="title-name">Tên báo cáo:</div>
                <div>{open?.form_name}</div>
              </Col>
              <Col span={6} className="d-flex">
                <div className="title-name">Trang thái:</div>
                <div>
                  {open?.status === 0
                    ? "Đang chờ"
                    : open?.status === 1
                    ? "Tiếp nhận"
                    : "Từ chối"}
                </div>
              </Col>
              <Col span={4} className="d-flex">
                <div className="title-name">Mức độ:</div>
                <div>{open?.allcode?.value}</div>
              </Col>
              <Col span={8} className="d-flex">
                <div className="title-name">Người gửi: </div>
                <div>{open?.User?.fullname}</div>
              </Col>
              <Col span={6} className="d-flex">
                <div className="title-name">Ngày gửi: </div>
                <div>{open?.form_log?.start_date}</div>
              </Col>
              <Col span={24}>
                <iframe
                  src={open?.form_url}
                  title="title"
                  style={{ width: "90%", height: "90vh" }}
                ></iframe>
              </Col>
            </Row>
          </div>
        </StyleDetails>
      </Modal>
    </div>
  )
}

export default DetailsReport
