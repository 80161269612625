import React, { useEffect, useState } from 'react';
import CustomModal from 'src/components/Modal/CustomModal';
import { Col, Form, Row } from 'antd';
import { ModalOpeningStyle } from '../../../styled';
import IconLogo from '../../../../../../assets/images/RehearsalLogo/LogoCyty.png';
import { ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import ButoonCustomStyle from './ButoonCustomStyle';
import SvgIcon from 'src/components/SvgIcon';
import Notice from 'src/components/Notice';
import ActivityService from 'src/services/ActivityService';
import PractiseService from 'src/services/PractiseService';
import RectangleImg from '../../../../../../assets/images/RehearsalLogo/Rectangle.png';
import moment from 'moment';
import TableCustom from 'src/components/Table/CustomTable';
import { columns } from './tagData';

// import { useDispatch } from 'react-redux';
// import Button from 'src/components/MyButton/Button';
// import Notice from 'src/components/Notice';
// import moment from 'moment';
// import FormService from 'src/services/FormSevice';


const ModalClose = ({ open, ok, onCancel, practise_id }) => {

    // const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(1);
    const dateStart = moment(value.data?.start_date).format('DD-MM-YYYY HH:mm:ss')
    const startDate = value ? moment(value.start_date).format('DD/MM/YYYY') : '';
    const endDate = value ? moment(value.end_date).format('DD/MM/YYYY') : '';
    const startTime = value ? moment(value.start_date).format('HH:mm') : '';
    const endTime = value ? moment(value.end_date).format('HH:mm') : '';
    
    const dateString = value ? (startDate === endDate ? `Ngày ${startDate}` : `Ngày ${startDate} - ${endDate}`) : '';
    const timeString = value ? (startTime === endTime ? `${startTime}` : `${startTime} - ${endTime}`) : '';

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const requestBody = {
                practise_id: practise_id
            };
            const res = await ActivityService.PostClosePractise(requestBody);

            if (res && res.isOk) {
                // window.open(res.data, "_blank");
                Notice({ msg: res.message, isSuccess: true })
                onCancel()
            }
        } catch (error) {
            Notice({ msg: error.message, isSuccess: false })
        } finally {
            setLoading(false);
        }
    }
    const GetPractisesByID = async () => {
        setLoading(true)
        try {
            const requestBody = {
                practise_id: practise_id
            };
            const res = await PractiseService.getPractisesByID(requestBody);
            if (res && res.isOk) {
                setValue(res.data)
                // Notice({ msg: res.message, isSuccess: true })
            }
        } catch (error) {
            Notice({ msg: error.message, isSuccess: false })
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        GetPractisesByID()
    }, []);

    // const renderCustomFooter = () => (
    //     <div style={{ textAlign: 'right', marginTop: '10px', display: "flex", justifyContent: "space-between", width: "100%", padding: "0px 15px 0px 8px" }}>
    //         <Button className='' style={{ marginRight: '8px', background: "#248C4E", color: "white" }}>Xem trước PDF</Button>
    //         <Button btnType="primary" onClick={handleSubmit} >Ghi lại</Button>

    //     </div>
    // );
    const columns1 = columns;
    return (
        <CustomModal
            title="Bế mạc diễn tập"
            width="1080px"
            footer={null}
            open={open}
            onOk={ok}
            onCancel={onCancel}
        >
            <ModalOpeningStyle>
                <Row gutter={[16, 16]} className="boxOpening" style={{ backgroundImage: value && value.banner_start_url ? `url(${value.banner_start_url})` : `url(${RectangleImg})` }}>
                    <Col span={24} style={{ width: "100%", height: "auto", paddingBottom: "30px" }}>
                        <Col span={18} className="boxText" >
                            <Col span={24} className="boxLogoIcon" style={{ paddingTop: "30px" }}>
                                <img src={IconLogo} alt="LogoIcon" />
                               
                            </Col>
                            <Col span={24} className="BoxTexOpening">
                                <Col span={23} className="ContenTextClose">
                                    BẾ MẠC:
                                    {value && value.practise_name ? value.practise_name : ' diễn tập không được xác định'}
                                </Col>
                            </Col>
                            <Col span={24} className="iconDate">
                                <div className='texDate'  >
                                    {/* Thêm văn bản và biểu tượng đồng hồ */}
                                    <ClockCircleOutlined style={{ fontSize: '16px', marginRight: '10px' }} />
                                    <span>{`${timeString} | ${dateString}`}</span>
                                </div>
                                <div className='texDate'>
                                    {/* Thêm văn bản và biểu tượng địa điểm */}
                                    <EnvironmentOutlined style={{ fontSize: '16px', marginRight: '10px' }} />
                                    <span>{value && value.practise_address ? value.practise_address : ' diễn tập không được xác định'}</span>
                                </div>
                            </Col>
                            <Col span={24} className="boxButton" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Col onClick={() => handleSubmit()}>
                                    <ButoonCustomStyle backgroundColor="#248C4E"
                                        color="white"
                                        text="Bế mạc"
                                        icon={<SvgIcon name="iconButtonMuitenphai" />}
                                    />
                                </Col>
                            </Col>
                        </Col>
                    </Col>
                </Row>
                <Row gutter={[10,10]} style={{paddingTop:"20px"}}>
                    <Col span={24} style={{display:"flex", justifyContent:"center",fontWeight:"bold" }}>
                        BẢNG XẾP HẠNG
                    </Col>
                    <Col span={24}>
                    <TableCustom
                    isPrimary
                    columns={columns1}
                    // dataSource={listData?.Data}
                    sticky={{ offsetHeader: -12 }}
                    // pagination={{
                    //     hideOnSinglePage: listData?.Total <= 10,
                    //     current: pagination.CurrentPage,
                    //     PageSize: pagination.PageSize,
                    //     responsive: true,
                    //     total: listData?.Total,
                    //     locale: { items_per_page: "" },
                    //     showSizeChanger: listData?.Total > 10,
                    //     onChange: (CurrentPage, PageSize) => {
                    //         setPagination({
                    //             ...pagination,
                    //             CurrentPage,
                    //             PageSize,
                    //         })
                    //     },
                    // }}
                    showPagination
                    rowKey={"CreateDate"}
                    footerLeft={<div className="d-flex mt-20" />}
                    widthScroll={1200}
                    textEmpty="Không có dữ liệu!"
                  />
                    </Col>
                </Row>

            </ModalOpeningStyle>


        </CustomModal>

    );
}

export default ModalClose;
