import React, { useEffect, useState } from "react"
import { IConCustomTonggerStyle, RehearsalManagementStyle } from "./styled"
import {
  Card,
  Col,
  DatePicker,
  Form,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tooltip,
} from "antd"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import { StyleSearch } from "../BookUse/styled"
import Search from "antd/es/input/Search"
import Button from "src/components/MyButton/Button"
import SvgIcon from "src/components/SvgIcon"
import NewRehearsal from "./Components/NewRehearsal"
import RegisterR from "./Components/RegisterR"
import DetailRe from "./Components/DetailRe"
import { useNavigate } from "react-router-dom"
import PractiseService from "src/services/PractiseService"
import moment from "moment"
import PaginationCustom from "src/components/Pagination"
import STORAGE, { getStorage } from "src/lib/storage"
import ModalOpening from "./Components/Components/Modals/ModalOpening"
import ModalClose from "./Components/Components/Modals/ModalClose"
import IConCustomTongger from "./Components/Components/Modals/IConCustomTongger"
import Notice from "src/components/Notice"
import Delete from "src/components/Modal/Delete"
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  PlayCircleOutlined,
  StopOutlined,
} from "@ant-design/icons"
import { MainTableData } from "src/components/Table/CustomTable/styled"

const { RangePicker } = DatePicker
const RehearsalManagement = ({ isClient }) => {
  const userInfo = getStorage(STORAGE.USER_INFO)
  const [pagination, setPagination] = useState({
    page_size: 12,
    page_number: 1,
    schedule_option: 0,
  })
  const isLogin = getStorage(STORAGE.TOKEN)
  const navigate = useNavigate()
  const [isNewModal, setIsNewModal] = useState(false)
  const [isModalOpening, SetIsModalOpening] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isRegister, setIsRegister] = useState(false)
  const [isDetail, setIsDetail] = useState(false)
  const [dataItem, setDataItem] = useState()
  const [isModalClose, setIsModalClose] = useState(false)
  const [btn, setBtn] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [practise_id, setPractise_id] = useState()
  const [form] = Form.useForm()
  const [totalItems, setTotalItems] = useState(0)
  const items = [
    {
      key: "0",
      label: <div className="text-tile-tabs">Tất cả diễn tập</div>,
      // children: 'Content of Tab Pane 2',
    },

    {
      key: "2",
      label: <div className="text-tile-tabs">Diễn tập của tôi</div>,
      // children: 'Content of Tab Pane 2',
    },
    {
      key: "1",
      label: <div className="text-tile-tabs">Lịch của tôi</div>,
      // children: 'Content of Tab Pane 1',
    },
  ]
  const getData = async () => {
    try {
      setLoading(true)
      const resp = !isLogin
        ? await PractiseService.getAllPractises({
            ...pagination,
          })
        : await PractiseService.getPractises({
            ...pagination,

            user_id: !!userInfo?.id ? userInfo?.id : undefined,
          })
      if (resp.isOk) {
        // const practisesWithRoleGroup = resp?.data.map(practise => {
        //   const userInfoItem = userInfo.role?.practise?.find(
        //     user => user.practise_id === practise.id,
        //   )
        //   if (userInfoItem) {
        //     practise.role_group_id = userInfoItem.role_group_id
        //   }
        //   return practise
        // })
        setDataItem(resp?.data)
        setTotalItems(resp.total)
        setBtn(resp?.button)
      }
    } finally {
      setLoading(false)
    }
  }

  const onChangeTable = key => {
    setPagination({
      ...pagination,
      schedule_option: key,
    })
  }
  // tìm kiếm
  const onSearch = (value, _e, info) => {
    setPagination({
      ...pagination,
      input_data: !!value ? value : undefined,
    })
  }
  // date
  const onChange = (date, dateString) => {
    setPagination({
      ...pagination,
      start_date: !!dateString[0] ? dateString[0] : undefined,
      end_date: !!dateString[1] ? dateString[1] : undefined,
    })
  }
  // status
  const handleSchedule = async event => {
    try {
      setLoading(true)
      const res = await PractiseService.addToSchedule({
        practise_id: event.id,
        type: event.type,
      })
      if (res.isOk) {
        getData()
        Notice({ msg: res.message, isSuccess: true })
      }
    } finally {
      setLoading(false)
    }
  }
  const onChangeSelect = event => {
    setPagination({
      ...pagination,
      status: !!event ? event : undefined,
    })
  }

  const DeletePractiseByID = async event => {
    setLoading(true)
    try {
      const res = await PractiseService.deletePractiseByID(event)
      if (res && res.isOk) {
        // window.open(res.data, "_blank");
        getData()
        //  Notice({ msg: res.message, isSuccess: true });
        Notice({ msg: "Đã xóa Diễn tập", isSuccess: true })
      }
    } catch (error) {
      Notice({ msg: error.message, isSuccess: false })
    } finally {
      setLoading(false)
    }
  }

  const handlerClickModal = event => {
    SetIsModalOpening(true)
    setPractise_id(event)
  }
  const handlerClickModalClose = event => {
    setIsModalClose(true)
    setPractise_id(event)
  }
  const updatePractiseByID = event => {
    setIsModalClose(true)
    setPractise_id(event)
  }

  useEffect(() => {
    getData()
  }, [pagination])

  return (
    <AdminStyled>
      <RehearsalManagementStyle>
        <Form form={form} layout="vertical" className="mt-20"></Form>

        <Row gutter={[15, 15]}>
          {!isClient ? (
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Tabs
                    type="card"
                    defaultActiveKey="0"
                    items={items}
                    onChange={onChangeTable}
                  />
                </Col>
                <Col span={24}>
                  <Row gutter={[20]}>
                    <Col span={13}>
                      <StyleSearch>
                        <Search
                          placeholder="Tìm kiếm theo tên diễn tập"
                          allowClear
                          onSearch={onSearch}
                          // style={{
                          //     width: 200,
                          // }}
                        />
                      </StyleSearch>
                    </Col>
                    <Col span={7}>
                      <RangePicker onChange={onChange} />
                    </Col>
                    <Col span={4}>
                      <Select
                        style={{
                          width: "100%",
                        }}
                        showSearch
                        allowClear
                        placeholder="Trạng thái"
                        optionFilterProp="children"
                        onChange={onChangeSelect}
                        // onSearch={onSearchSelect}
                        // filterOption={filterOption}
                        options={[
                          {
                            value: "1",
                            label: "Sắp diễn ra",
                          },
                          {
                            value: "2",
                            label: "Đang diễn ra",
                          },
                          {
                            value: "3",
                            label: "Đã diễn ra",
                          },
                          {
                            value: "4",
                            label: "Kết thúc",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ) : (
            <>
              {!!userInfo && (
                <Col span={24}>
                  <Tabs
                    type="card"
                    defaultActiveKey="0"
                    items={items}
                    onChange={onChangeTable}
                  />
                </Col>
              )}
            </>
          )}

          <Col span={24}>
            <Spin size="small" spinning={loading} style={{ width: "100%" }}>
              {/* <RehearsalManagementStyle> */}
              <Row gutter={[16, 16]}>
                {!isClient && (
                  <Col span={24}>
                    <div className="title-type-1">
                      <Row className="justify-content-space-between">
                        <Col
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="Text-title">
                            Danh sách diễn tập thực chiến ({totalItems})
                          </div>
                        </Col>
                        <Col>
                          <Space>
                            {!!btn?.btn_CreatePrac && (
                              <Button
                                btnType="primary"
                                onClick={() => {
                                  // navigate(ROUTER.PROCEDURE)
                                  // getData(2)
                                  setIsNewModal(true)
                                }}
                              >
                                Thêm Diễn tập
                              </Button>
                            )}
                          </Space>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )}
                <Col span={24}>
                  <Row gutter={[20, 30]}>
                    {!!dataItem &&
                      dataItem?.map((items, index) => {
                        let statusLabel
                        let statusColor
                        let statusIcon
                        switch (items.status) {
                          case "1":
                            statusLabel = "Sắp diễn ra"
                            statusColor = "#FFC107"
                            statusIcon = <ClockCircleOutlined />
                            break
                          case "2":
                            statusLabel = "Đang diễn ra"
                            statusColor = "#4CAF50"
                            statusIcon = <PlayCircleOutlined />
                            break
                          case "3":
                            statusLabel = "Đã diễn ra"
                            statusColor = "#3F51B5"
                            statusIcon = <CheckCircleOutlined />
                            break
                          case "4":
                            statusLabel = "Kết thúc"
                            statusColor = "#F44336"
                            statusIcon = <StopOutlined />
                            break
                          default:
                            statusLabel = "Unknown"
                            statusColor = "#000000"
                            statusIcon = null
                            break
                        }
                        return (
                          <Col lg={8} xxl={6}>
                            <Card
                              hoverable
                              style={{
                                width: "100%",
                                borderRadius: "20px",
                                objectFit: "cover",
                              }}
                              cover={
                                <img
                                  style={{ height: "195px" }}
                                  alt="example"
                                  src={
                                    items?.practise_img_url ||
                                    require("./demo.png")
                                  }
                                />
                              }
                            >
                              <Row gutter={[10, 8]}>
                                <Col
                                  span={24}
                                  style={{ height: "50px", overflow: "hidden" }}
                                >
                                  <h4
                                    style={{
                                      fontSize: "15px",
                                      color: "#00549A",
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 2,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        `/chi-tiet-dien-tap/${items?.id}`,
                                        { state: { itemData: items } },
                                      )
                                    }}
                                  >
                                    {items?.practise ? (
                                      <span>
                                        {items?.practise.practise_name}
                                      </span>
                                    ) : (
                                      <span>{items?.practise_name}</span>
                                    )}
                                  </h4>
                                </Col>
                                <Col span={24}>
                                  <Row gutter={[30]}>
                                    <Col span={12}>
                                      <Space>
                                        <SvgIcon name="calender-company" />
                                        <Tooltip
                                          title={
                                            <div>
                                              {moment(items?.start_date).format(
                                                "DD/MM/YYYY HH:mm",
                                              )}
                                            </div>
                                          }
                                        >
                                          <MainTableData>
                                            {moment(items?.start_date).format(
                                              "DD/MM/YYYY HH:mm",
                                            )}
                                          </MainTableData>
                                        </Tooltip>
                                      </Space>
                                    </Col>
                                    <Col span={12}>
                                      <Space>
                                        <SvgIcon name="map-company" />
                                        <Tooltip
                                          title={
                                            <div>{items?.practise_address}</div>
                                          }
                                        >
                                          <MainTableData>
                                            {items?.practise_address}
                                          </MainTableData>
                                        </Tooltip>
                                      </Space>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row gutter={[30]}>
                                    <Col span={12} className="pl-19">
                                      <Space>
                                        <SvgIcon name="user_login" />
                                        <Tooltip
                                          title={
                                            <div>
                                              {items?.quality_user +
                                                " Người tham gia" || ""}
                                            </div>
                                          }
                                        >
                                          <MainTableData>
                                            {items?.quality_user +
                                              " Người tham gia" || ""}
                                          </MainTableData>
                                        </Tooltip>
                                      </Space>
                                    </Col>
                                    <Col
                                      span={12}
                                      className="pl-19"
                                      style={{
                                        color: statusColor,
                                        fontWeight: "500",
                                      }}
                                    >
                                      <Space>
                                        <div
                                          style={{
                                            width: "16px",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {statusIcon}
                                        </div>
                                        {statusLabel}
                                      </Space>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  {!!isLogin && (
                                    <Row>
                                      {pagination.schedule_option !== "2" && (
                                        <>
                                          <Col span={12}>
                                            <Button
                                              className="w-100"
                                              type="primary"
                                              onClick={() => {
                                                setIsRegister(
                                                  items?.user_exist == 0
                                                    ? items.id
                                                    : undefined,
                                                )
                                              }}
                                            >
                                              {items?.user_exist == 0
                                                ? "Đăng ký"
                                                : items?.user_exist == 1
                                                ? "Đã Đăng ký"
                                                : "Đã tham gia"}
                                            </Button>
                                          </Col>

                                          <Col
                                            span={12}
                                            className="title-p"
                                            style={{
                                              paddingLeft: "35px",
                                              color:
                                                items.user_register == 0
                                                  ? "#00549a"
                                                  : "red",
                                            }}
                                          >
                                            {pagination?.schedule_option !==
                                              "2" && (
                                              <div
                                                onClick={() => {
                                                  handleSchedule({
                                                    id: items.id,
                                                    type:
                                                      items.user_register == "0"
                                                        ? "1"
                                                        : "0",
                                                  })
                                                }}
                                              >
                                                {items.user_register == "0"
                                                  ? "Thêm vào lịch"
                                                  : "Bỏ khỏi lịch"}
                                              </div>
                                            )}
                                          </Col>
                                        </>
                                      )}
                                      {pagination.schedule_option == 2 && (
                                        <Row
                                          gutter={[4, 16]}
                                          className="boxIcon w-100pe pl-8"
                                          style={{}}
                                        >
                                          <Col
                                            span={24}
                                            className="d-flex justify-content-center"
                                          >
                                            <Popover
                                              content={
                                                !!items.role_group_id &&
                                                items.role_group_id === 7 ? (
                                                  <IConCustomTonggerStyle>
                                                    <Col
                                                      span={24}
                                                      className="wrapper"
                                                      style={{ height: "100%" }}
                                                    >
                                                      {/* <IConCustomTongger
                                          nameSvg="meeting"
                                          iconColor=""
                                          backgroundColor=""
                                          nameToolTip="meeting"
                                          backgroundColorToolTip="#A825F9"
                                          backgroundbefore="#A825F9"
                                        /> */}
                                                      {/* <IConCustomTongger
                                          nameSvg="chat"
                                          iconColor=""
                                          backgroundColor=""
                                          nameToolTip="Chat"
                                          backgroundColorToolTip="#0E8CF1"
                                          backgroundbefore="#0E8CF1"
                                        /> */}
                                                      <IConCustomTongger
                                                        nameSvg="duyetdangky"
                                                        iconColor=""
                                                        backgroundColor=""
                                                        nameToolTip="Duyệt đăng ký"
                                                        backgroundColorToolTip="#BCF0FF"
                                                        backgroundbefore="#BCF0FF"
                                                      />
                                                      <IConCustomTongger
                                                        nameSvg="configTypeProfile"
                                                        iconColor=""
                                                        backgroundColor=""
                                                        nameToolTip="Phân quyền"
                                                        backgroundColorToolTip="#2525d0"
                                                        backgroundbefore="#2525d0"
                                                      />
                                                      {/* <IConCustomTongger
                                          nameSvg="tiepnhanbaocao"
                                          iconColor=""
                                          backgroundColor=""
                                          nameToolTip="Tiếp nhận báo cáo"
                                          backgroundColorToolTip="#248C4E
                                                                    "
                                          backgroundbefore="#248C4E
                                                                    "
                                        /> */}
                                                      <IConCustomTongger
                                                        nameSvg="baocaocucATTT"
                                                        iconColor=""
                                                        backgroundColor=""
                                                        nameToolTip="báo cáo cục ATTT"
                                                        backgroundColorToolTip="#248C4E"
                                                        backgroundbefore="#248C4E"
                                                      />
                                                      {/* <IConCustomTongger
                                          nameSvg="baocaotancong"
                                          iconColor=""
                                          backgroundColor=""
                                          nameToolTip="Báo cáo tấn công"
                                          backgroundColorToolTip="red"
                                          backgroundbefore="red"
                                        /> */}
                                                      {/* <div>
                                          <IConCustomTongger
                                            nameSvg="baocaodoiphongthu"
                                            iconColor=""
                                            backgroundColor=""
                                            nameToolTip="Báo cáo đội phòng thủ"
                                            backgroundColorToolTip="#0E8CF1"
                                            backgroundbefore="#0E8CF1"
                                          />
                                        </div> */}

                                                      {/* <div
                                          onClick={() =>
                                            handlerClickModal(items?.id)
                                          }
                                        >
                                          <IConCustomTongger
                                            nameSvg="khaimac"
                                            iconColor=""
                                            backgroundColor=""
                                            nameToolTip="Khai mạc"
                                            backgroundColorToolTip="red"
                                            backgroundbefore="red"
                                          />
                                        </div> */}

                                                      {/* <div
                                          onClick={() =>
                                            handlerClickModalClose(items?.id)
                                          }
                                        >
                                          <IConCustomTongger
                                            nameSvg="close-icon"
                                            iconColor=""
                                            backgroundColor=""
                                            nameToolTip="Bế mạc"
                                            backgroundColorToolTip="#0E8CF1"
                                            backgroundbefore="#0E8CF1"
                                          />
                                        </div> */}
                                                      <div
                                                        onClick={() =>
                                                          navigate(
                                                            `/sua-dien-tap/${items?.id}`,
                                                          )
                                                        }
                                                      >
                                                        <IConCustomTongger
                                                          nameSvg="sua"
                                                          iconColor=""
                                                          backgroundColor=""
                                                          nameToolTip="Sửa"
                                                          backgroundColorToolTip="#00C590"
                                                          backgroundbefore="#00C590"
                                                        />
                                                      </div>

                                                      <div
                                                        onClick={() =>
                                                          setOpenDeleteModal(
                                                            items?.id,
                                                          )
                                                        }
                                                      >
                                                        <IConCustomTongger
                                                          nameSvg="xoa"
                                                          iconColor=""
                                                          backgroundColor=""
                                                          nameToolTip="Xóa"
                                                          backgroundColorToolTip="#ED1117"
                                                          backgroundbefore="#ED1117"
                                                        />
                                                      </div>
                                                      {/* <div >
                                                                    <IConCustomTongger
                                                                        nameSvg="doimatkhau"
                                                                        iconColor=""
                                                                        backgroundColor=""
                                                                        nameToolTip="Đổi mật khẩu"
                                                                        backgroundColorToolTip="#0D99FF"
                                                                        backgroundbefore="#0D99FF"
                                                                    />
                                                                </div> */}
                                                      {/* <div >
                                                                    <IConCustomTongger
                                                                        nameSvg="lichsu"
                                                                        iconColor=""
                                                                        backgroundColor=""
                                                                        nameToolTip="Lịch sử"
                                                                        backgroundColorToolTip="#FFD600"
                                                                        backgroundbefore="#FFD600"
                                                                    />
                                                                </div> */}
                                                      {/* <div>
                                          <IConCustomTongger
                                            nameSvg="danhgia"
                                            iconColor=""
                                            backgroundColor=""
                                            nameToolTip="Đánh giá"
                                            backgroundColorToolTip="#37474F"
                                            backgroundbefore="#37474F"
                                          />
                                        </div> */}
                                                    </Col>
                                                  </IConCustomTonggerStyle>
                                                ) : (
                                                  "Bạn không có quyền"
                                                )
                                              }
                                              title="Chức năng"
                                              trigger="click"
                                            >
                                              <Button
                                                style={{
                                                  width: "90%",
                                                }}
                                              >
                                                Button Chức năng
                                              </Button>
                                            </Popover>
                                          </Col>
                                        </Row>
                                      )}
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        )
                      })}
                  </Row>
                </Col>
                <Col span={24}>
                  <PaginationCustom
                    bottom={-4}
                    option={{
                      hideOnSinglePage: totalItems >= 12 && false,
                      current: pagination?.page_number,
                      pageSize: pagination?.page_size,
                      responsive: true,
                      total: totalItems,
                      locale: { items_per_page: "" },
                      // showSizeChanger: dataTable?.total > 10,
                      onChange: (page, pageSize) => {
                        setPagination({
                          ...pagination,
                          page_number: page,
                          page_size: pageSize,
                        })
                      },
                    }}
                  />
                </Col>
              </Row>

              {/* </RehearsalManagementStyle> */}
            </Spin>
          </Col>
        </Row>
      </RehearsalManagementStyle>
      {isNewModal && (
        <NewRehearsal
          getData={() => getData()}
          open={isNewModal}
          onOk={() => setIsNewModal(false)}
          onCancel={() => setIsNewModal(false)}
        />
      )}
      {!!isRegister && (
        <RegisterR
          open={isRegister}
          onOk={() => setIsRegister(false)}
          onCancel={() => setIsRegister(false)}
          getData={getData}
        />
      )}
      {isDetail && (
        <DetailRe
          open={isDetail}
          onOk={() => setIsDetail(false)}
          onCancel={() => setIsDetail(false)}
        />
      )}
      {isModalOpening && (
        <ModalOpening
          practise_id={practise_id}
          open={isModalOpening}
          onOk={() => SetIsModalOpening(false)}
          onCancel={() => SetIsModalOpening(false)}
        />
      )}
      {isModalClose && (
        <ModalClose
          practise_id={practise_id}
          open={isModalClose}
          onOk={() => setIsModalClose(false)}
          onCancel={() => setIsModalClose(false)}
        />
      )}
      {openEdit && (
        <NewRehearsal
          practise_id={openEdit}
          getData={() => getData()}
          open={openEdit}
          onOk={() => setOpenEdit(false)}
          onCancel={() => setOpenEdit(false)}
          edit={true}
        />
      )}
      {openDeleteModal && (
        <Delete
          isTitle="Xác nhận xóa"
          isOpen={!!openDeleteModal}
          content={{
            title: (
              <div className="fw-600 text-center" style={{ fontSize: 16 }}>
                Bạn có chắc chắn muốn xóa diễn tập không ? "
                {/* <b style={{ color: 'red', fontWeight: '600' }}>{openDeleteModal.title}</b>" không? */}
              </div>
            ),
          }}
          onOk={() => {
            setOpenDeleteModal(false)
            DeletePractiseByID(openDeleteModal)
          }}
          onCancel={() => setOpenDeleteModal(false)}
        />
      )}
    </AdminStyled>
  )
}

export default RehearsalManagement
