// UPLOAD

export const apiGetForms = "/quytrinh/api/v1/GetForms"
export const apiCreateFormBM01 = "/quytrinh/api/v1/CreateFormBM01"
export const apiCreateFormBM02 = "/quytrinh/api/v1/CreateFormBM02"
export const apiCreateFormBM03 = "/quytrinh/api/v1/CreateFormBM03"
export const apiCreateFormBM04 = "/quytrinh/api/v1/CreateFormBM04"
export const apiCreateFormBM05 = "/quytrinh/api/v1/CreateFormBM05"

export const apiGetListError = "/quytrinh/api/v1/GetListError"
export const apiGetLevelError = "/quytrinh/api/v1/GetLevelError"
export const apiReceiveForm = "/quytrinh/api/v1/ReceiveForm"
export const apiGetErrorForms = "/quytrinh/api/v1/GetErrorForms"

export const apiGetFormsProcedure = "/quytrinh/api/v1/GetForms"
export const apiGetFormByID = "/quytrinh/api/v1/GetFormByID"
