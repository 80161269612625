import React, { useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css" // Import quill styles
import styled from "styled-components"
const QuillWrapper = styled.div`
  .ql-container.ql-snow {
    height: 160px;
    border-radius: 0px 0px 12px 12px;
  }
`
const QillEditor = ({ value, onChange }) => {
  const handleChange = (content, delta, source, editor) => {
    onChange(content)
  }

  return (
    <QuillWrapper>
      <ReactQuill
        value={value}
        onChange={handleChange}
        modules={QillEditor.modules}
        formats={QillEditor.formats}
        bounds={".app"}
        placeholder="Write something amazing..."
      />
    </QuillWrapper>
  )
}

// Quill modules
QillEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}

// Quill formats
QillEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default QillEditor
