import { Col, Form, Modal, Row, Space, Upload } from "antd"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SvgIcon from "src/components/SvgIcon"
import FileService from "src/services/FileService"
import PractiseService from "src/services/PractiseService"

const PeopleAdd = ({ open, onOk, onCancel, getData }) => {
  const [avatarUpload, setAvatarUpload] = useState("")
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { id } = useParams()

  const uploadImg = async file => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("file_list", file)
      formData.append("practise_id", id)
      const res = await FileService.uploadFile(formData)
      if (res.isError) return
      setAvatarUpload(res.data[0])
    } finally {
      setLoading(false)
    }
  }
  const handleChangeSubmit = async () => {
    try {
      setLoading(true)
      // Tạo body request
      const requestBody = {
        practise_id: id,
        file_url: avatarUpload?.url,
      }
      let requestChange =
        open == 1
          ? PractiseService.importUserByFileForInvite(requestBody)
          : PractiseService?.importTeamPracByFile(requestBody)
      const res = await requestChange
      if (res.isError) return
      if (res.isOk) {
        Notice({
          isSuccess: true,
          msg: res?.message,
        })
        //   form.resetFields() // Reset form fields
        //   setAvatarUpload([]) // Clear uploaded files
        onCancel()
        getData()
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      title={(open == 1 ? "Thêm người" : "Thêm đội") + " tham gia diễn tập"}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      width={1000}
      footer={
        <Space>
          <Button
            btnType="primary"
            className="btn-hover-shadow"
            type="submit"
            htmlType="submit"
            loading={loading}
            onClick={() => handleChangeSubmit()}
          >
            Ghi lại
          </Button>
        </Space>
      }
    >
      <AdminStyled>
        <Form form={form} layout="vertical" scrollToFirstError>
          <Row gutter={[20, 15]}>
            <Col span={24}>
              <Row>
                <Col span={2}>Tải file mẫu :</Col>
                <Col span={22}>
                  <a
                    target="_blank"
                    href={
                      open == 1
                        ? "https://demo.vnresearchlab.com/list-cds/public/private/e57bd9a8-5c06-4f7a-867c-38ec658617d0.xlsx"
                        : "https://demo.vnresearchlab.com/list-cds/public/private/3b457226-e765-4056-9df1-4baff06b90ad.xlsx"
                    }
                  >
                    Tải file tại đây
                  </a>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <div className="text-note">
                Lưu ý: Người trong danh sách thêm phải là người đã có tài khoản
                trên hệ thống, người chưa có tài khoàn trên hệ thống sẽ không
                thể mời bằng nhập file.
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Upload file"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "File tài liệu ảnh không được để trống!",
                  },
                ]}
              >
                <Upload.Dragger
                  multiple={false}
                  beforeUpload={file => {
                    uploadImg(file)
                    return false
                  }}
                  maxCount={1}
                  onChange={info => {
                    if (info.fileList.length > 1) {
                      info.fileList = [info.fileList[info.fileList.length - 1]]
                    }
                    // setDataArray(info.fileList)
                  }}
                  accept=".xlsx"
                >
                  <div className="d-flex justify-content-center">
                    <SvgIcon name="cloud-upload" />
                    <div className="ml-5">
                      Kéo thả file đính kèm hoặc{" "}
                      <span style={{ color: "#2260BD" }}>Chọn File</span>
                    </div>
                  </div>
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AdminStyled>
    </Modal>
  )
}

export default PeopleAdd
