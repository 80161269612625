import React from "react"
import RehearsalManagement from "../RehearsalManagement"

const Management = () => {
  return (
    <div>
      <RehearsalManagement />
    </div>
  )
}

export default Management
