// import { Spin } from "antd"
import React from "react"
import { useRoutes } from "react-router-dom"
import ROUTER from "./index"
import { BallTriangle } from "react-loader-spinner"
import Management from "src/pages/ADMIN/Management"

// ANONYMOUS
const Contribute = React.lazy(() => import("src/pages/ANONYMOUS/Contribute"))
const New = React.lazy(() => import("src/pages/ANONYMOUS/New"))
const DetailNew = React.lazy(() =>
  import("src/pages/ANONYMOUS/New/detailNew/DetailNew"),
)
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))
const NotFound = React.lazy(() => import("src/pages/NotFound"))
const Home = React.lazy(() => import("src/pages/ANONYMOUS/Home"))
const LoginPage = React.lazy(() => import("src/pages/ANONYMOUS/LoginPage"))
const RegisterPage = React.lazy(() => import("src/pages/ANONYMOUS/Register"))
const InfoAccount = React.lazy(() => import("src/pages/ANONYMOUS/InfoAccount"))
const Handbook = React.lazy(() => import("src/pages/ANONYMOUS/Handbook"))
const Practise = React.lazy(() => import("src/pages/ANONYMOUS/Practise"))
const References = React.lazy(() => import("src/pages/ANONYMOUS/References"))
const Procedure = React.lazy(() => import("src/pages/ANONYMOUS/Procedure"))
const DetailProcedure = React.lazy(() =>
  import("src/pages/ANONYMOUS/Procedure/Components/DetailProcedure"),
)
const Details = React.lazy(() => import("src/pages/ANONYMOUS/Details"))

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ChangePassword = React.lazy(() => import("src/pages/USER/ChangePassword"))
const SendDocument = React.lazy(() => import("src/pages/USER/SendDocument"))

// ADMIN
const RehearsalManagement = React.lazy(() =>
  import("src/pages/ADMIN/RehearsalManagement"),
)
const Detail = React.lazy(() => import("src/pages/ADMIN/Detail/Detail"))
const DashboardStatistical = React.lazy(() =>
  import("src/pages/ADMIN/DashboardStatistical"),
)
const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))
const ClusterAdd = React.lazy(() => import("src/pages/ADMIN/ClusterAdd"))
const ListError = React.lazy(() => import("src/pages/ADMIN/ListError"))
const BookUse = React.lazy(() => import("src/pages/ADMIN/BookUse"))
const TableEvaluate = React.lazy(() => import("src/pages/ADMIN/TableEvaluate"))
const DocManagement = React.lazy(() => import("src/pages/ADMIN/DocManagement"))
const FormManagement = React.lazy(() =>
  import("src/pages/ADMIN/FormManagement"),
)
const Comments = React.lazy(() => import("src/pages/ADMIN/Comments"))
const ActivityHistory = React.lazy(() =>
  import("src/pages/ADMIN/ActivityHistory"),
)
const Role = React.lazy(() => import("src/pages/ADMIN/Role"))
const ListReport = React.lazy(() => import("src/pages/ADMIN/ListReport"))

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          {/* <Spin /> */}
          <BallTriangle
            height={50}
            width={50}
            radius={5}
            color="#01638d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },
  // ADMIN
  {
    element: (
      <LazyLoadingComponent>
        <AminRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.QUAN_LY_DIEN_TAP,
        element: (
          <LazyLoadingComponent>
            <Management />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TIEU_CHI_DANH_GIA,
        element: (
          <LazyLoadingComponent>
            <TableEvaluate />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DONG_GOP_Y_KIEN,
        element: (
          <LazyLoadingComponent>
            <Comments />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <DocManagement />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_FORM_MAU,
        element: (
          <LazyLoadingComponent>
            <FormManagement />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_BA_NGUOI_DUNG,
        element: (
          <LazyLoadingComponent>
            <BookUse />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PHAN_QUYEN,
        element: (
          <LazyLoadingComponent>
            <Role />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.LICH_SU_HOAT_DONG,
        element: (
          <LazyLoadingComponent>
            <ActivityHistory />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DASHBOARD,
        element: (
          <LazyLoadingComponent>
            <DashboardStatistical />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CHI_TIET,
        element: (
          <LazyLoadingComponent>
            <Detail />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_BAO_CAO,
        element: (
          <LazyLoadingComponent>
            <ListReport />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CUM,
        element: (
          <LazyLoadingComponent>
            <ClusterAdd />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DS_LOI,
        element: (
          <LazyLoadingComponent>
            <ListError />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DOI_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ChangePassword />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.SEND_BC,
        element: (
          <LazyLoadingComponent>
            <SendDocument />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CAM_NANG,
        element: (
          <LazyLoadingComponent>
            <Handbook />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_NHAP,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_KY,
        element: (
          <LazyLoadingComponent>
            <RegisterPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT,
        element: (
          <LazyLoadingComponent>
            <InfoAccount />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DONG_GOP,
        element: (
          <LazyLoadingComponent>
            <Contribute />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TIN_TUC,
        element: (
          <LazyLoadingComponent>
            <New />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CHI_TIET_TIN_TUC,
        element: (
          <LazyLoadingComponent>
            <DetailNew />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DIEN_TAP,
        element: (
          <LazyLoadingComponent>
            <Practise />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <References />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PROCEDURE,
        element: (
          <LazyLoadingComponent>
            <Procedure />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DETAIL_PROCEDURE,
        element: (
          <LazyLoadingComponent>
            <DetailProcedure />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DETAILS_PROCEDURE,
        element: (
          <LazyLoadingComponent>
            <Details />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <LazyLoadingComponent>
        <NotFound />
      </LazyLoadingComponent>
    ),
  },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter
