import {
  apiGetPractises,
  apiGetPractisesByID,
  apiCreatePractise,
  apiUpdateRegion,
  apiDeleteRegion,
  apiGetLocationVN,
  apiGetByRegionId,
  apiGetAllRegions,
  apiUpdatePractiseByID,
  apiDeletePractiseByID,
  apiAddUserInPractise,
  apiGetUserInPractise,
  apiAddNewFileInPractise,
  apiGetAllFilesInPractise,
  apiRegisterToPractise,
  apiGetRegisterInPractise,
  apiApprovalOfRregistration,
  apiGetPositionInPractise,
  apiAddBannerInfo,
  apiGetBannerInfo,
  apiGetDeployPlan,
  apiAddDeployPlan,
  apiDeleteDeployPlan,
  apiGetUserForPractise,
  apiAddTeamInPractise,
  apiGetRankingOfPractise,
  apiGetRoleInPractise,
  apiGetEvaluateOfPractise,
  apiAddPointToTeam,
  apiGetTeamInPractise,
  apiGetAllPractises,
  apiConfirmInviteOfPractise,
  apiSendNotiToAll,
  apiDeleteFileByID,
  apiMoveUsertoTeam,
  apiAllowPublishResult,
  apiGetPublishResult,
  apiAddToSchedule,
  apiRefuseInviteOfPractise,
  apiImportUserByFileForInvite,
  apiImportTeamPracByFile,
  apiGetNotiToAll,
} from "./urls"
import http from "../index"
const getPositionInPractise = params =>
  http.get(apiGetPositionInPractise, { params })
const getDeployPlan = params => http.get(apiGetDeployPlan, { params })
const allowPublishResult = body => http.post(apiAllowPublishResult, body)
const getNotiToAll = params => http.get(apiGetNotiToAll, { params })

const getUserForPractise = params => http.get(apiGetUserForPractise, { params })
const getPublishResult = params => http.get(apiGetPublishResult, { params })

const getEvaluateOfPractise = params =>
  http.get(apiGetEvaluateOfPractise, { params })

const getRankingOfPractise = params =>
  http.get(apiGetRankingOfPractise, { params })

const getPractises = params => http.get(apiGetPractises, { params })
const getRoleInPractise = params => http.get(apiGetRoleInPractise, { params })

const getAllFilesInPractise = params =>
  http.get(apiGetAllFilesInPractise, { params })
const addDeployPlan = body => http.post(apiAddDeployPlan, body)
const addToSchedule = body => http.post(apiAddToSchedule, body)
const refuseInviteOfPractise = body =>
  http.post(apiRefuseInviteOfPractise, body)

const addTeamInPractise = body => http.post(apiAddTeamInPractise, body)
const getTeamInPractise = body => http.post(apiGetTeamInPractise, body)
const confirmInviteOfPractise = body =>
  http.post(apiConfirmInviteOfPractise, body)
const sendNotiToAll = body => http.post(apiSendNotiToAll, body)
const moveUsertoTeam = body => http.post(apiMoveUsertoTeam, body)

const getUserInPractise = params => http.get(apiGetUserInPractise, { params })
const addUserInPractise = body => http.post(apiAddUserInPractise, body)
const addNewFileInPractise = body => http.post(apiAddNewFileInPractise, body)
const addBannerInfo = body => http.post(apiAddBannerInfo, body)
const getBannerInfo = params => http.get(apiGetBannerInfo, { params })
const addPointToTeam = body => http.post(apiAddPointToTeam, body)

const getPractisesByID = params => http.get(apiGetPractisesByID, { params })
const getLocationVN = params => http.get(apiGetLocationVN, { params })
const createPractise = body => http.post(apiCreatePractise, body)
const approvalOfRregistration = body =>
  http.post(apiApprovalOfRregistration, body)

const updateRegion = body => http.put(apiUpdateRegion, body)
const deleteRegion = params => http.patch(apiDeleteRegion + params)
const getByRegionId = params => http.get(apiGetByRegionId, { params })
const GetAllRegions = params => http.get(apiGetAllRegions, { params })
const getAllPractises = params => http.get(apiGetAllPractises, { params })

const getRegisterInPractise = params =>
  http.get(apiGetRegisterInPractise, { params })

const updatePractiseByID = body => http.put(apiUpdatePractiseByID, body)
// const deletePractiseByID = body => http.delete(apiDeletePractiseByID, body)
const deletePractiseByID = params =>
  http.delete(apiDeletePractiseByID + `?id=${params}`)
const deleteDeployPlan = params => http.delete(apiDeleteDeployPlan, { params })
const deleteFileByID = params => http.delete(apiDeleteFileByID, { params })

const registerToPractise = body => http.post(apiRegisterToPractise, body)
// const DeleteEvaluationCriterial = params => http.delete(apiDeletePractiseByID + `/${(params)}`)
const importUserByFileForInvite = body =>
  http.post(apiImportUserByFileForInvite, body)
const importTeamPracByFile = body => http.post(apiImportTeamPracByFile, body)

const PractiseService = {
  getNotiToAll,
  importTeamPracByFile,
  importUserByFileForInvite,
  refuseInviteOfPractise,
  addToSchedule,
  getPublishResult,
  allowPublishResult,
  moveUsertoTeam,
  deleteFileByID,
  sendNotiToAll,
  confirmInviteOfPractise,
  getAllPractises,
  getTeamInPractise,
  addPointToTeam,
  getEvaluateOfPractise,
  getRoleInPractise,
  getRankingOfPractise,
  addTeamInPractise,
  getUserForPractise,
  deleteDeployPlan,
  addDeployPlan,
  getDeployPlan,
  getBannerInfo,
  addBannerInfo,
  addNewFileInPractise,
  getAllFilesInPractise,
  getPositionInPractise,
  approvalOfRregistration,
  registerToPractise,
  deletePractiseByID,
  updatePractiseByID,
  getRegisterInPractise,
  addUserInPractise,
  getUserInPractise,
  getPractisesByID,
  getPractises,
  createPractise,
  updateRegion,
  deleteRegion,
  getLocationVN,
  getByRegionId,
  GetAllRegions,
}
export default PractiseService
