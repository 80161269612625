import { InboxOutlined } from "@ant-design/icons"
import { Col, Form, Modal, Radio, Row, Space, message } from "antd"
import Dragger from "antd/es/upload/Dragger"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SvgIcon from "src/components/SvgIcon"
import STORAGE, { getStorage } from "src/lib/storage"
import { normFile } from "src/lib/utils"
import FileService from "src/services/FileService"
import PractiseService from "src/services/PractiseService"

const ModalAdd = ({ open, onOk, onCancel, getData }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [avatarUpload, setAvatarUpload] = useState("")
  const [value, setValue] = useState(1)
  const { id } = useParams()
  const userInfo = getStorage(STORAGE.USER_INFO)

  const handleChangeSubmit = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      // Tạo body request
      const requestBody = {
        ...values,
        practise_id: id,
        user_id: userInfo.id,
        docs: avatarUpload,
      }
      console.log(requestBody)

      const res = await PractiseService.addNewFileInPractise(requestBody)
      if (res.isError) return
      if (res.isOk) {
        Notice({
          isSuccess: true,
          msg: "Thêm tập tin thành công",
        })
        onCancel()
        getData()
      }
    } finally {
      setLoading(false)
    }
  }
  const uploadImg = async file => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("file_list", file)
      formData.append("practise_id", id)
      const res = await FileService.uploadFile(formData)
      const newFile = res.data[0]
      const formattedFile = {
        uid: newFile.id || file.uid,
        name: newFile.title || file.name,
        status: "done",
        url: newFile.url,
        size: file.size,
        id: newFile.id,
        title: newFile.title || file.name,
        updatedAt: newFile.updatedAt || new Date().toISOString(),
        createdAt: newFile.createdAt || new Date().toISOString(),
      }
      if (res.isError) {
        message.error(`${file.name} file upload failed.`)
        return
      }
      setAvatarUpload(prevState => [...prevState, formattedFile])
    } finally {
      setLoading(false)
    }
  }
  const props = {
    name: "file_list",
    multiple: true,
    customRequest({ file, onSuccess, onError }) {
      uploadImg(file)
        .then(() => {
          onSuccess(null, file)
        })
        .catch(onError)
    },
    onChange(info) {
      const { status } = info.file
      if (status !== "uploading") {
        // console.log(info.file, info.fileList)
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onRemove(file) {
      const listArray = avatarUpload.filter(item => item.name !== file.name)
      setAvatarUpload(listArray)
      console.log("check delete fiel:", avatarUpload, file, listArray)
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    },
  }
  return (
    <div>
      <Modal
        title="Thêm tài liệu"
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              loading={loading}
              onClick={() => handleChangeSubmit()}
            >
              Ghi lại
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical" scrollToFirstError>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Loại tài liệu"
                name="type_file"
                rules={[
                  {
                    required: true,
                    message: "hệ thông diễn tập không được để trống",
                  },
                ]}
              >
                <Radio.Group
                //   onChange={event => {
                //     setValue(event.target.value)
                //   }}
                //   value={value}
                >
                  <Radio value={1}>Công khai</Radio>
                  <Radio value={2}>Bảo mật</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Upload tài liệu"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                name="docs"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!!value?.find(i => i?.size > 5 * 1024 * 1024)) {
                        return Promise.reject(
                          new Error("Dung lượng file tối đa 5MB"),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Kéo thả file đính kèm hoặc Chọn File
                  </p>
                  {/* <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited
                    from uploading company data or other banned files.
                  </p> */}
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default ModalAdd
